import React from "react";
import Star from "../assets/star.svg";
import { renderRichText } from "gatsby-source-contentful/rich-text";

const Skills = ({ texts }) => {
  return (
    <section id="skills__section">
      <h3 className="section__title">
        <span className="outline">{texts?.our}</span>
        <span className="glysa">
          {texts?.skills} <Star alt="Star" />
        </span>
      </h3>

      <ul className="skills__list">
        {texts?.skillsList?.map((skill, idx) => (
          <li key={idx} className="skills__item">
            {skill}
          </li>
        ))}
      </ul>

      <div className="section__description">
        {renderRichText(texts?.companyDescription)}
      </div>
    </section>
  );
};

export default Skills;
