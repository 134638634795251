import React, { useEffect } from "react";
import Helmet from "react-helmet";

const StructuredData = ({ seoImages }) => {
  return (
    <Helmet>
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "ProfessionalService",
            "name": "Northic Software Boutique",
            "url": "https://northic.io",
            "sameAs": "https://www.northic.io",
            "image": [${seoImages.map((image) => `"${image.url}"`)}],
            "email": "hey@northic.io",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "C. 11 635 3C",
              "addressLocality": "La Plata, Provincia de Buenos Aires",
              "addressRegion": "BA",
              "postalCode": "1900",
              "addressCountry": "AR"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": -34.9150583,
              "longitude": -57.9609067
            }
          }
       `}
      </script>
    </Helmet>
  );
};

export default StructuredData;
