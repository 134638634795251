import React, { useEffect, useState } from "react";
import Isologo from "../../assets/isologo.svg";
import IsologoSmall from "../../assets/isologo-small.svg";
import Indexer from "./Indexer";
import "./styles.scss";
import { navigate, withPrefix } from "gatsby-link";

const NavItem = ({ href, text }) => (
  <li className="navigation__item" data-href={href}>
    <a href={href}>{text}</a>
  </li>
);

const Header = ({ texts }) => {
  const [isOpen, setIsOpen] = useState(false);

  const items = [
    {
      text: texts.home,
      section: "#home__section",
    },
    {
      text: texts.skills,
      section: "#skills__section",
    },
    {
      text: texts.works,
      section: "#works__section",
    },
    {
      text: texts.contact,
      section: "#contact__section",
    },
  ];
  const mappedItems = items.map((item, idx) => (
    <NavItem key={idx} href={item.section} text={item.text} />
  ));

  const [showFullBackground, setShowFullBackground] = useState(false);

  const changeLang = (language) => {
    if (language) {
      window.localStorage.setItem("lang", language);
      if (language !== "en") {
        navigate(withPrefix(`/${language}`));
      } else {
        navigate(withPrefix("/"));
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY >= 150;
      setShowFullBackground(show);
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return [
    <header
      className={`site-header ${showFullBackground ? "full-background" : ""}`}
    >
      <a href="#home__section">
        {showFullBackground ? (
          <IsologoSmall
            className="logo"
            alt="Northic Logo"
            aria-label="Northic Logo"
          />
        ) : (
          <Isologo
            className="logo"
            alt="Northic Logo"
            aria-label="Northic Logo"
          />
        )}
      </a>
      <div className="menu-button">
        <button
          className={`hamburger hamburger--elastic ${
            isOpen ? "is-active" : ""
          }`}
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          aria-label="Menu"
        >
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>
      </div>
      <nav
        className={`navigation ${isOpen ? "open" : ""}`}
        onClick={() => setIsOpen(false)}
      >
        <ul>
          {mappedItems}
          <li className="navigation__language" onClick={() => changeLang("es")}>
            ES
          </li>
          <li className="navigation__language" onClick={() => changeLang("en")}>
            EN
          </li>
        </ul>
      </nav>
    </header>,
    <Indexer items={items} />,
  ];
};

export default Header;
