import React from "react";
import Header from "./Header";
import Home from "./Home";
import Skills from "./Skills";
import Team from "./Team";
import Works from "./Works";
import Contact from "./Contact";
import Footer from "./Footer";

const Main = ({ texts, portfolio }) => {
  const {
    home,
    scrollDown,
    send,
    skills,
    skillsList,
    software,
    studio,
    teamOne,
    teamThree,
    teamTwo,
    touch,
    works,
    email,
    getIn,
    our,
    boutique,
    contact,
    companyDescription,
    formSuccessMessage,
    formErrorMessage,
    name,
    message,
  } = texts;
  return (
    <main id="main" className="container">
      <Header
        texts={{
          home,
          skills,
          works,
          contact,
        }}
      />
      <Home
        texts={{
          scrollDown,
          software,
          studio,
          boutique,
        }}
      />
      <Skills
        texts={{
          skillsList,
          companyDescription,
          our,
          skills,
        }}
      />
      <Team
        texts={{
          teamOne,
          teamTwo,
          teamThree,
        }}
      />
      <Works
        texts={{
          our,
          works,
        }}
        portfolio={portfolio}
      />
      <Contact
        texts={{
          send,
          touch,
          email,
          getIn,
          name,
          message,
          formSuccessMessage,
          formErrorMessage,
        }}
      />
      <Footer />
    </main>
  );
};

export default Main;
