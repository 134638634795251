import React from 'react';
import Logo from '../assets/logo.svg';

const Footer = () => {
  return (
    <footer>
      <p>Nothing is reserved © 2021 Northic</p>
      <Logo alt="Northic" className="logo" />
    </footer>
  )
}

export default Footer
