import React, { useState, useEffect } from "react";

const IndexItem = ({ item, index, currentSection }) => {
  const isSelected =
    currentSection?.toLowerCase() === item?.text?.toLowerCase();
  return (
    <a
      href={item?.section}
      className={`index__item ${isSelected ? "active" : ""}`}
    >
      {isSelected ? item?.text : index + 1}
    </a>
  );
};

const getTopPosition = (element) =>
  document.querySelector(element).getBoundingClientRect().top;

const Indexer = ({ items }) => {
  const [currentSection, setCurrentSection] = useState("home");
  useEffect(() => {
    const handleScroll = () => {
      items.forEach(({ section, text }) => {
        if (getTopPosition(section) <= 90) {
          setCurrentSection(text?.toLowerCase());
        }
      });
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [items]);
  return (
    <ul className="index__container">
      {items.map((item, index) => (
        <IndexItem
          item={item}
          key={index}
          index={index}
          currentSection={currentSection}
        />
      ))}
    </ul>
  );
};

export default Indexer;
