import React from 'react'

const Team = ({ texts }) => {
  return (
    <section id="team__section">
      <h2 className="contact__title">
        <span>{texts.teamOne}</span>
        <span className="glysa">{texts.teamTwo}</span>
        <span>{texts.teamThree}</span>
      </h2>
    </section>
  )
}

export default Team
