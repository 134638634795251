import React, { useState } from "react";
import Star from "../assets/star.svg";
import Copy from "../assets/copy.svg";
import Check from "../assets/check.svg";

const Contact = ({ texts }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    navigator.clipboard.writeText("hey@northic.io");
    setTimeout(() => {
      setCopied(false);
    }, 5000);
  }

  return (
    <section id="contact__section">
      <div id="contact__mail">
        <a href="mailto:hey@northic.io">hey@northic.io </a>
        
        <button className="contact__copybutton">
          {copied ? <Check alt="Check" /> : <Copy alt="Copy" onClick={handleCopy} />}
        </button>
      </div>

      <h3 className="section__title">
        <span className="outline">{texts.getIn}</span>
        <span className="glysa">
          {texts.touch} <Star alt="Star" />
        </span>
      </h3>
    </section>
  );
};

export default Contact;
