import React from "react";
import '../styles/global.scss';
import '../styles/home.scss';

const Home = ({ texts }) => {
  return (
    <section id="home__section">
      <h2 className="home__title">
        <span>{texts.software}</span>
        <span className="glysa">
          {texts.boutique}
        </span>
        <span>{texts.studio}</span>
      </h2>
      <div className="home__controls">
        <a
          className="cta"
          href="#skills__section"
        >
          {texts.scrollDown} <div>&nbsp; ↓</div>
        </a>

        <footer>
          <a href="#" className="social__item">
            Instagram
          </a>
          <a href="#" className="social__item">
            LinkedIn
          </a>
        </footer>
      </div>
    </section>
  );
};

export default Home;
