import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Star from "../assets/star.svg";

const SingleWork = ({ work: { title, image, url, category, description } }) => (
  <a className="works__item" href={url} target="_blank" rel="noreferrer">
    <div>
      {image}
      <p className="work__title">
        {title} <span className="work__category">// {category}</span>{" "}
      </p>
      <p className="work__description">{description}</p>
    </div>
  </a>
);

const Works = ({ texts, portfolio }) => {
  const works = portfolio
    .sort((a, b) => a.order - b.order)
    .map((w) => ({
      title: w.title,
      description: w.description,
      category: w.category,
      url: w.url,
      image: (
        <GatsbyImage
          key={w.order}
          image={getImage(w.image)}
          alt={w.title}
          className="works__image"
        />
      ),
    }));

  return (
    <section id="works__section">
      <h3 className="section__title">
        <span className="outline">{texts.our}</span>
        <span className="glysa">
          {texts.works} <Star alt="Star" />
        </span>
      </h3>

      <div className="works__container">
        {works.map((work, idx) => (
          <SingleWork key={idx} work={work} />
        ))}
      </div>
    </section>
  );
};

export default Works;
